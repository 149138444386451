<template>
  <div class="w-100 bg-footer p-2">
  <!--begin row -->
    <div class="row">
      <!--begin col-md-12 -->
      <div class="col-md-12 text-center">
        <p>Copyright © 2022 UselfBank</p>
        <!--begin footer_social -->
        <div class="flex align-items-center justify-content-center mb-5"><a aria-label="twitter" href="#" class="cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block inline-flex justify-content-center align-items-center mr-5" style="width: 2.5rem; height: 2.5rem;"><i class="pi pi-twitter"></i></a><a href="#" aria-label="Facebook"  class="cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block inline-flex justify-content-center align-items-center mr-5" style="width: 2.5rem; height: 2.5rem;"><i class="pi pi-facebook"></i></a><a href="#" aria-label="Github" class="cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block inline-flex justify-content-center align-items-center" style="width: 2.5rem; height: 2.5rem;"><i class="pi pi-github"></i></a></div>
        <!--end footer_social -->
      </div>
    <!--end col-md-6 -->
    </div>
    <!--end row -->
  </div>
</template>
